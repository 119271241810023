html {
    width: 100%;
    height: 100%;
}

body {
    text-align: center;
    background-image: url("img/BG.jpg");
    background-size: cover;
    background-repeat: no-repeat;
}

.green-text {
    color: darkgreen;
}

.red-text {
    color: darkred;
}

button {
    margin: 0px auto;
    background-color: rgb(194, 141, 255);
    transition: all 0.3s ease 0s;
    border: 0px;
    font-size: 1.7rem;
    color: white;
    border-radius: 2rem;
    padding: 1rem 3rem;
}

button:hover {
    cursor: pointer;
    background-color: rgb(79, 37, 128);
}

.swap-trace-container {
    margin-top: 2rem;
}

.container, .swap-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.swap-details-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.container {
    background-color: rgba(0,0,0,0.6);
    padding: 2rem;
    margin: 4rem;
    display: flex;
    color: white;
    border-radius: 40px;
}

.table-container {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
}

.tokens-container {
    display: flex;
}

.from,
.to {
    margin: 4vw;
}

#swap {
    margin-top: 60px;
}

.arrow {
    margin-top: calc(80px + 4vw);
    width: 100px;
    filter: invert(1);
}

.failure {
    width: 40px;
}

.success {
    width: 50px;
}

.from-amount {
    width: 4rem;
}

.loading {
    display: inline-block;
    width: 40px;
    height: 40px;
    filter: invert(1);
}

.loading:after {
    content: ' ';
    display: block;
    width: 32px;
    height: 32px;
    margin: 4px;
    border-radius: 50%;
    border: 3px solid #000;
    border-color: #000 transparent #000 transparent;
    animation: loading 1.2s linear infinite;
}

.token-logo {
    margin-bottom: 12px;
}

.input-amount {
    font-size: 2rem;
    /* margin-bottom: 12px; */
}

.input-subamount {
    margin-bottom: 12px;
}

@keyframes loading {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.error-message {
    color: lightcoral;
}

table td {
    border: 1px solid gray;
    padding: 5px 4px;
    min-width: 200px;
}

.arrow-mobile {
    display: none;
}

@media screen and (max-width: 749px) {
    .tokens-container {
        flex-direction: column;
    }

    .arrow {
        display: none;
    }

    .arrow-mobile {
        display: block;
    }

    table td {
        min-width: none;
    }

    .swap-details-container {
        order: 5;
    }

    .container {
        margin: 1.5rem;
    }

    #swap {
        margin-top: 20px;
    }
}

button:disabled {
    opacity: 0.6;
    pointer-events: none;
}